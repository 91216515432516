import { TuiRoot } from '@taiga-ui/core';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { BaseComponent, BaseModule } from 'client/modules/_base/base';

@Component({
  selector: 'lc-root',
  standalone: true,
  imports: [RouterOutlet, TuiRoot, BaseModule],
  template: `
    <tui-root>
      <router-outlet></router-outlet>
    </tui-root>

    <ng-container ngProjectAs="tuiOverContent"> </ng-container>
    <ng-container ngProjectAs="tuiOverDialogs"> </ng-container>
    <ng-container ngProjectAs="tuiOverAlerts"> </ng-container>
    <ng-container ngProjectAs="tuiOverDropdowns"> </ng-container>
    <ng-container ngProjectAs="tuiOverHints"> </ng-container>
  `,
})
export class AppComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    this.userService.user$.subscribe();
  }
}
