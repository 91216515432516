import { Component } from '@angular/core';

import { TuiLink } from '@taiga-ui/core';

@Component({
  selector: 'lc-root',
  standalone: true,
  templateUrl: './_.component.html',
  styleUrls: ['./_.component.scss'],
  imports: [TuiLink],
})
export class UnavailableComponent {}
