import { bootstrapApplication } from '@angular/platform-browser';

import WebApp from '@twa-dev/sdk';
import { Platforms } from '@twa-dev/types';

import eruda from 'eruda';

import * as Sentry from '@sentry/angular';

import { UnavailableComponent } from './modules/pages/_unavailable/_.component';

import { AppComponent } from './modules/app.component';
import { appConfig } from './modules/app.config';

import { environment } from 'client/environments/environment';

// const allowedPlatforms: Platforms[] = ['ios', 'android'];
const allowedPlatforms: Platforms[] = ['macos', 'ios', 'android', 'tdesktop'];

if (environment.TYPE === 'development' || environment.TYPE === 'ngrok') {
  eruda.init();

  allowedPlatforms.push('unknown');
} else if (environment.TYPE === 'production') {
  Sentry.init({
    dsn: 'https://9c7c1b37dd6b912c9d87bdc2c6103388@o4508163249078272.ingest.de.sentry.io/4508166818562128',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (allowedPlatforms.includes(WebApp.platform)) {
  WebApp.ready();
  WebApp.expand();
  // WebApp.requestFullscreen();
  WebApp.enableClosingConfirmation();
  WebApp.disableVerticalSwipes();

  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err),
  );
} else {
  bootstrapApplication(UnavailableComponent, { providers: [] }).catch((err) =>
    console.error(err),
  );
}
