import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TuiButton, TuiFallbackSrcPipe, TuiIcon } from '@taiga-ui/core';
import { TuiAvatar, TuiChip, TuiProgress } from '@taiga-ui/kit';
import { TuiHeader } from '@taiga-ui/layout';

import { BaseComponent, BaseModule } from 'client/modules/_base/base';

@Component({
  selector: 'lc-layout-header-block',
  standalone: true,
  imports: [
    TuiFallbackSrcPipe,
    TuiChip,
    TuiProgress,
    TuiHeader,

    BaseModule
  ],
  providers: [],
  templateUrl: './header.block.html',
  styleUrls: ['header.block.scss'],
})
export class LayoutHeaderBlock extends BaseComponent {

}
