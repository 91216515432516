<div class="unavailable">
  <div class="tui-text_h2">Lazy Combat</div>

  <a class="tui-space_top-5" href="https://t.me/LazyCombatBot">
    <img
      class="unavailable__qr tui-space_vertical-3"
      [src]="'/assets/images/qr.png'"
      [alt]="'@LazyCombat'"
    />
  </a>

  <div class="tui-space_top-5 tui-text_h5">Play on your mobile</div>

  <a tuiLink class="tui-space_top-2" href="https://t.me/LazyCombatBot">
    <div class="tui-text_h6">
      {{ '@LazyCombat' }}
    </div>
  </a>
</div>
