<nav tuiTabBar class="d-flex align-items-center">
  <button
    tuiTabBarItem
    icon="@tui.house"
    routerLink="/home"
    routerLinkActive
  ></button>

  <button tuiTabBarItem icon="@tui.swords"></button>

  <button
    tuiTabBarItem
    icon="@tui.gavel"
    routerLink="/auction"
    routerLinkActive
  ></button>

  <button
    tuiTabBarItem
    icon="@tui.users"
    routerLink="/community/friends"
    routerLinkActive
  ></button>
</nav>
