import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { TuiTabBar } from '@taiga-ui/addon-mobile';

@Component({
  selector: 'lc-layout-footer-block',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, TuiTabBar],
  templateUrl: './footer.block.html',
})
export class LayoutFooterBlock {}
