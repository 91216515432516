import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';

import WebApp from '@twa-dev/sdk';

import { Observable } from 'rxjs';

import { environment } from 'client/environments/environment';

export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  let requestUrl = request.url;

  if (requestUrl.indexOf('@api') !== -1) {
    requestUrl = requestUrl.replace('@api', environment.API_URL);
  }

  request = request.clone({
    url: requestUrl,
    setHeaders: {
      'ngrok-skip-browser-warning': '69420',
    },
  });

  if (environment.TYPE === 'ngrok' || environment.TYPE === 'production') {
    request = request.clone({
      setHeaders: {
        Authorization: `tma ${WebApp.initData}`,
      },
    });
  }

  return next(request);
};
