import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LayoutFooterBlock } from './blocks/footer/footer.block';
import { LayoutHeaderBlock } from './blocks/header/header.block';

@Component({
  selector: 'lc-layout',
  standalone: true,
  imports: [RouterOutlet, LayoutFooterBlock, LayoutHeaderBlock],
  templateUrl: './_.page.html',
  styleUrls: ['_.page.scss'],
})
export class LayoutPage {}
